<template>
  <div>
    <loading-screen v-if="isLoading"></loading-screen>
    <v-card v-else>
      <v-card-title>
        Daftar User
        <v-spacer></v-spacer>
        <div>
          <v-select v-model="sortBy" :items="sortByItems" dense></v-select>
          <v-row style="max-width: 600px">
            <v-col>
              <v-text-field
                v-model="search"
                dense
                label="Search"
                class="mb-2"
                hide-details
                small
                :disabled="sortBy != 'all data'"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-select
                :disabled="sortBy != 'all data'"
                v-model="field"
                :items="items"
                dense
              ></v-select>
              <v-btn
                color="green"
                style="color: white; float: right"
                @click="doSearch"
                :loading="isLoading"
                small
              >
                Find
              </v-btn>
              <v-btn
                color="red"
                style="color: white; float: right"
                @click="resetSearch"
                :loading="isLoading"
                small
                :disabled="sortBy != 'all data'"
              >
                Reset
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card-title>
      <v-data-table
        :headers="sorted ? headersSortBy : headers"
        hide-default-footer
        :items="datas"
        :items-per-page="perPage"
        mobile-breakpoint="0"
      >
        <template v-slot:item="row">
          <tr>
            <td>{{ row.item.fullName || "Kosong" }}</td>
            <td>{{ row.item.email }}</td>
            <td>
              {{ row.item.phoneNumber || "Kosong" }}
              <v-chip
                v-if="row.item.isPhoneNumberVerified == 'false'"
                small
                class="ma-2"
                color="red"
                text-color="white"
              >
                Unverified
              </v-chip>
              <v-chip
                v-if="row.item.isPhoneNumberVerified == 'true'"
                small
                class="ma-2"
                color="green"
                text-color="white"
              >
                Verified
              </v-chip>
            </td>
            <td>{{ row.item.age || "Kosong" }}</td>
            <td v-if="sorted">{{ row.item.COUNT }}</td>
            <td>{{ format_date(row.item.created_at) }}</td>
            <td>
              <v-chip
                v-if="row.item.isVerified == false"
                small
                class="ma-2"
                color="blue-grey"
                text-color="white"
              >
                Unverified
              </v-chip>
              <v-chip
                v-if="row.item.isVerified == true"
                small
                class="ma-2"
                color="green"
                text-color="white"
              >
                Verified
              </v-chip>
            </td>
            <td>
              <v-btn
                small
                depressed
                color="primary"
                @click="$router.push(`/user/${row.item.id}`)"
              >
                Detail
              </v-btn>
            </td>
          </tr>
        </template></v-data-table
      >
      <div class="float-right ma-3">
        <v-pagination
          v-model="currentPage"
          :length="totalPages"
          circle
          @input="handlePageChange"
        ></v-pagination>
      </div>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import LoadingScreen from "../components/LoadingScreen.vue";

export default {
  name: "User",

  components: { LoadingScreen },

  data() {
    return {
      search: "",
      headersSortBy: [
        { text: "Nama", value: "fullName" },
        { text: "Email", value: "email" },
        { text: "No Telp", value: "phoneNumber" },
        { text: "Umur", value: "age" },
        { text: "Total", value: "COUNT" },
        { text: "Dibuat", value: "created_at" },
        { text: "Status", value: "isVerified" },
        { text: "Atur", value: "" },
      ],
      headers: [
        { text: "Nama", value: "fullName" },
        { text: "Email", value: "email" },
        { text: "No Telp", value: "phoneNumber" },
        { text: "Umur", value: "age" },
        { text: "Dibuat", value: "created_at" },
        { text: "Status", value: "isVerified" },
        { text: "Atur", value: "" },
      ],
      sorted: false,
      datas: [],
      field: "email",
      sortBy: "all data",
      sortByItems: [
        "accepted on tournament",
        "regist on tournament",
        "create tournament",
        "all data",
      ],
      items: ["fullName", "email"],
      currentPage: 1,
      isLoading: true,
      perPage: 0,
    };
  },

  methods: {
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD-MM-YYYY");
      }
    },

    resetSearch() {
      this.search = "";
      this.currentPage = 1;
      this.fetch();
    },

    doSearch() {
      this.currentPage = 1;
      this.fetch();
    },

    fetch() {
      const AuthStr = "Bearer ".concat(this.$store.getters.getToken);

      if (this.sortBy == "all data") {
        this.sorted = false;
        var url = this.search
          ? `${process.env.VUE_APP_API_URL}/v2/admin/users?page=${this.currentPage}&key=${this.search}&field=${this.field}`
          : `${process.env.VUE_APP_API_URL}/v2/admin/users?page=${this.currentPage}`;
      } else {
        this.search = "";
        this.sorted = true;
        let sortBy = this.sortBy.split(" ").join("_");
        var url = `${process.env.VUE_APP_API_URL}/v2/admin/users?page=${this.currentPage}&sort_by=${sortBy}`;
      }

      axios
        .create({ withCredentials: false })
        .get(url, {
          headers: { Authorization: AuthStr },
        })
        .then((response) => {
          this.isLoading = false;

          this.datas = response.data.result.data;
          this.totalPages = response.data.result.last_page;
          this.perPage = response.data.result.per_page;
        })
        .catch((error) => {
          this.$toast.warning(error.response.data.message || "Unauthorized");
          this.loading = false;
          console.log(error);
        });
    },

    handlePageChange(value) {
      this.currentPage = value;
      this.fetch();
    },
  },

  mounted() {
    this.fetch();
  },
};
</script>
